import styled from "styled-components"
import { breakpoints, Img } from "../../utils/styles"

export const ProductInfoWrapper = styled.div`
  position: relative;

  @media (min-width: ${breakpoints.m}px) {
    position: fixed;
    padding: 0 0 165px;
  }
`

export const ProductTitle = styled.h1`
  font-size: 2.25rem;
  margin-bottom: 15px;
  word-wrap: break-word;
  font-family: "HelveticaNeue-Regular";
  font-weight: 400;
  margin: 0 0 0.5rem;
  line-height: 1.4;
  color: #000000;
`

export const ProductPrice = styled.h3`
  font-size: 1.25rem;
  margin-bottom: 15px;
  word-wrap: break-word;
  font-family: "HelveticaNeue-Regular";
  font-weight: 400;
  margin: 0 0 0.5rem;
  line-height: 1.4;
  color: #000000;
`

export const ProductImage = styled(Img)`
  min-height: 275px;
  height: calc(50vw - 2rem - 15px);

  & img {
    object-fit: contain !important;
  }
`

export const ProductDescription = styled.div`
  margin: 1.5rem 0;
  font-family: "HelveticaNeue-Regular";
  font-weight: 300;
  color: #000000;
  max-width: 70ch;
  width: 85%;
`
