import React, { useState, useEffect } from "react"
import { graphql } from "gatsby"
import {
  Container,
  TwoColumnGrid,
  GridLeft,
  GridRight,
} from "../../utils/styles"
import SEO from "../../components/seo"
import {
  ProductInfoWrapper,
  ProductTitle,
  ProductImage,
  ProductPrice,
  ProductDescription,
} from "./styles"

const ProductPage = ({ data }) => {
  const prismicShopItem = data.prismicShopItem

  const { description, images, price, title } = prismicShopItem.data
  const buyButtonCode = prismicShopItem.data.buy_button_code
  const [pageContent, setPageContent] = useState("<div></div>")

  const createMarkup = () => {
    return { __html: `${pageContent}` }
  }

  useEffect(() => {
    let splitScript = buyButtonCode.split('<script type="text/javascript">')
    let div = splitScript[0]
    let scriptInner = splitScript[1].split("</script>")[0]
    setPageContent(div)

    let appendScript = document.createElement("script")

    appendScript.setAttribute("type", "text/javascript")
    appendScript.async = true
    appendScript.innerHTML = scriptInner
    document.body.appendChild(appendScript)

    return () => {
      document.body.removeChild(appendScript)
    }
  }, [buyButtonCode])

  return (
    <>
      <SEO title={title} />
      <Container>
        <TwoColumnGrid>
          <GridLeft>
            {images.map((item, index) => (
              <ProductImage
                fluid={item.image.localFile.childImageSharp.fluid}
                key={index}
                alt={`${title} - image ${index + 1} of ${images.length}`}
              />
            ))}
          </GridLeft>
          <GridRight>
            <ProductInfoWrapper>
              <ProductTitle>{title.toUpperCase()}</ProductTitle>
              <ProductPrice>{price}</ProductPrice>
              <ProductDescription
                dangerouslySetInnerHTML={{ __html: description.html }}
              />
              <div dangerouslySetInnerHTML={createMarkup()} />
            </ProductInfoWrapper>
          </GridRight>
        </TwoColumnGrid>
      </Container>
    </>
  )
}

export const query = graphql`
  query($urlSlug: String!) {
    prismicShopItem(data: { url_slug: { eq: $urlSlug } }) {
      data {
        images {
          image {
            localFile {
              childImageSharp {
                fluid(
                  maxWidth: 1920
                  srcSetBreakpoints: [650, 960, 1280, 1920]
                ) {
                  ...GatsbyImageSharpFluid_withWebp_noBase64
                }
              }
            }
          }
        }
        title
        price
        description {
          html
        }
        buy_button_code
      }
    }
  }
`

export default ProductPage
